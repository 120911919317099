    // Working Contact Form
    $('#contact-form').submit(function() {
        var action = $(this).attr('action');

        $("#message").slideUp(750, function() {
            $('#message').hide();

            $('#submit')
                .before('')
                .attr('disabled', 'disabled');

            $.post(action, {
                    name: $('#name').val(),
                    email: $('#email').val(),
                    comments: $('#comments').val(),
                },
                function(data) {
                    $('#contact-form-modal').modal('toggle');
                    $('#contact-form-modal').find('.modal-body').first().html(data);
                    $('#submit').removeAttr('disabled');
                    
                }
            ).fail(function(data) {
                    $('#contact-form-modal').modal('toggle');
                    $('#contact-form-modal').find('.modal-body').first().html("Noget gik galt!");
                    $('#submit').removeAttr('disabled');
            });

        });

        return false;

    });
      

// Example starter JavaScript for disabling form submissions if there are invalid fields
(function () {
    'use strict'
  
    window.addEventListener('load', function () {
      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      var forms = document.getElementsByClassName('needs-validation')
  
      // Loop over them and prevent submission
      Array.prototype.filter.call(forms, function (form) {
        form.addEventListener('submit', function (event) {
          if (form.checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
          }
          form.classList.add('was-validated')
        }, false)
      })
    }, false)
  }())